<template>
  <div class="privacyPage" @keydown="returnFn($event)">
    <div
      class="back tabindex"
      tabindex="1"
      @keydown="keyDown($event, '/')"
      @click="goPath('/setting')"
    >
      Exit
    </div>
    <div class="textContent">
      <h1>Privacy Policy</h1>
      <p>
        This privacy policy is designed to assist you in understanding what
        information we collect, why we collect it, and how you can update,
        manage, export, and delete your information.
      </p>

      <h2>
        We want you to understand what types of information we collect when you
        use our services.
      </h2>

      <p>
        We collect relevant information about the applications, browsers, and
        devices you use when accessing our services to provide practical
        features such as automatic product updates and screen dimming when the
        battery is low.
      </p>

      <p>
        The information we collect includes unique identifiers, browser types
        and settings, device types and settings, operating systems, network
        information, and application version numbers. We also collect
        information about your interactions with the application, including IP
        addresses, crash reports, system activities, and the date, time, and
        source URL of corresponding requests.
      </p>

      <p>
        We may collect information about your activities in the service (e.g.,
        videos you watch) and utilize this information, such as recommending
        videos you might like.
      </p>

      <p>
        We use various technologies to collect and store information, including
        cookies, pixel codes, local storage (e.g., using browser-based web
        storage or application data caching), databases, and server logs.
      </p>

      <h2>Why do we collect data?</h2>

      <ol>
        <li>
          <strong>Provide Services:</strong> We use your information to provide
          services, such as processing your IP and language settings.
        </li>
        <li>
          <strong>Maintain and Improve Services:</strong> We use your
          information to ensure the normal operation of services, track
          interruptions, or investigate issues you report to us. Additionally,
          we use your information to improve services.
        </li>
        <li>
          <strong>Develop New Services:</strong> We use information collected in
          existing services to assist in developing new services, tailored to
          user preferences.
        </li>
        <li>
          <strong>Evaluate Effectiveness:</strong> We use data for analysis and
          evaluation to understand the usage patterns of our services.
        </li>
      </ol>

      <h2>When do we share your information?</h2>

      <p>
        We do not share your personal information with companies, organizations,
        or individuals outside our company, except for the following:
      </p>

      <h3>For Legal Reasons:</h3>

      <p>
        If we have a good faith belief that it is necessary for the following
        purposes:
      </p>

      <ul>
        <li>
          Fulfilling any applicable laws, regulations, or legal procedures, or
          mandatory government requests. We will disclose information about the
          quantity and types of requests received from the government in
          transparency reports.
        </li>
        <li>
          Enforcing applicable terms of service, including investigating
          potential violations.
        </li>
        <li>
          Detecting, preventing, or addressing fraud, security, or technical
          issues.
        </li>
        <li>
          Protecting the rights, property, or safety of our users or the public
          within the scope permitted or required by law.
        </li>
      </ul>

      <p>
        We may publicly share or partner with others (such as publishers,
        advertisers, or rights holders) to share non-personal identity
        information. For instance, we may share information to provide an
        overview of the overall usage trends of our services. We also allow
        specific partners to use their own cookies or similar technologies for
        advertising or evaluation purposes, collecting information from your
        browser or device.
      </p>

      <p>
        In the event of asset mergers, acquisitions, or sales, we will continue
        to ensure the confidentiality of personal information and notify
        affected users before transferring personal information or subjecting
        personal information to other privacy policies.
      </p>

      <h2>
        We have built security features into our services to protect your
        information.
      </h2>

      <p>
        We make efforts to provide protection for you and your information,
        preventing unauthorized access, alteration, disclosure, or destruction
        of the information we possess. To achieve this, we implement numerous
        measures, including:
      </p>

      <ol>
        <li>
          Using encryption technology to ensure the confidentiality of your data
          during transmission.
        </li>
        <li>
          Providing various security features (such as secure browsing, security
          checks, and two-step verification) to assist you in protecting your
          account.
        </li>
        <li>
          Reviewing our practices in collecting, storing, and processing
          information, including physical security measures, to prevent
          unauthorized access to our systems.
        </li>
        <li>
          Allowing only company employees, contractors, and agents who need
          personal information for processing to access it. Anyone with this
          permission must comply with strict confidentiality obligations
          specified in the contract and may face disciplinary action or
          termination for violating these provisions.
        </li>
      </ol>

      <h2>Scope of this Policy</h2>

      <p>
        This privacy policy applies only to the affiliated services provided by
        the company.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goPath (path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.privacyPage {
  height: 100%;
  position: relative;
  .back {
    display: block;
    width: 80px;
    font-size: 30px;
    text-align: center;
    color: #fff;
    border-radius: 20px;
    &:focus {
      background: gray;
    }
  }
  .textContent{
    height: 720px;
    overflow-y: scroll;
    color: #fff;
    h1{
      text-align: center;
      font-size: 45px;
    }
    h2{
      font-size: 35px;
    }
    h3{
      font-size: 25px;
      text-indent: 20px;
    }
    p,ol,li{
      font-size: 25px;
      margin:20px 0px;
      text-indent: 40px;
    }
  }
}
</style>
